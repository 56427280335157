var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-0 mb-0",attrs:{"id":"sottoscrizioneNewsletter"}},[(_vm.submitted)?_c('div',{staticClass:"white--text grey darken-3 text-center text-h6 pt-15 pb-15"},[_vm._v(" Grazie per aver inviato la tua richiesta. Verrà elaborata nel minor tempo possibile. ")]):_vm._e(),(!_vm.submitted)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"grey darken-3 pt-6 pb-3",attrs:{"id":"formRichiestaTrial"}},[_c('div',{staticClass:"red--text-juranews text-center text-h5"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Richiesta iscrizione newsletter")])],1),_c('div',{staticClass:"white--text text-center text-h6"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Iscriviti subito alla newsletter di JuraNews, l’innovativo sistema informativo che permette al professionista di essere sempre aggiornato.")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dark":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Nome e Cognome / Studio Legale ")]},proxy:true}],null,true),model:{value:(_vm.identificativo),callback:function ($$v) {_vm.identificativo=$$v},expression:"identificativo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":'mdi-email',"type":"email","error-messages":errors,"dark":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Il tuo indirizzo e-mail ")]},proxy:true}],null,true),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":'mdi-phone',"dark":"","label":"Cellulare","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Il tuo numero di telefono ")]},proxy:true}],null,true),model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Es. +393287654985")])])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"grey--text text-center",attrs:{"cols":"8"}},[_c('div',[_vm._v(" Compilando il presente form e cliccando sul tasto \"Invia\" o contattandoci via email o telefonicamente, invierai i tuoi dati personali a Juranet s.r.l, che li tratterà per rispondere alla tua richiesta e/o fornirti il servizio richiesto, come descritto nell'"),_c('router-link',{staticClass:"red--text-juranews",staticStyle:{"cursor":"pointer"},attrs:{"to":"/policies/privacy","tag":"span"}},[_vm._v("informativa sulla Privacy")]),_vm._v(" che ti invitiamo a leggere con cura. ")],1)]),_c('v-col',{attrs:{"cols":"2"}})],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"8","md":"8"}},[_c('v-btn',{staticClass:"red text-h6 elevation-0 rounded-0 mt-2",attrs:{"disabled":invalid,"width":"200","dark":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" INVIA ")])],1),_c('v-col',{attrs:{"cols":"2"}})],1)],1)]}}],null,false,585288831)}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout,"centered":"","width":300,"height":"80"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red white--text","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" Chiudi ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }